@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url('./fonts/Raleway-Regular.ttf')
}

@font-face {
  font-family: 'Raleway-bold';
  src: local('Raleway-bold'), url('./fonts/Raleway-Bold.ttf')
}

html, body {
  font-family: 'Raleway', sans-serif;
}

body {
  margin: 0;
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway-bold', sans-serif;
}

@media (max-width: 575.98px) {
/* Manage Display Headers for Mobile Devices */
.display-4 {
font-size: 1.75rem;
font-weight: 300;
line-height: 1.0;
}
/* .intro-btn {
  padding:4px 9px;
    font-size:80%;
    line-height: 1.2;
} */
}

.project img {
  padding: 0.25rem;
  background-color: aliceblue;
  border: 1px solid #bdc3c7;
  border-radius: 0.25rem;
  width: 100%;
  height: 18rem;
}

.about-section-bullet {
  font-size: 0.4em;
  color: #dc3545;
  margin-right: 1.5rem;
}

.about-content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.about-section {
  padding-bottom: 20px;
}

/* Thin out the marketing headings */
.posts-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
  margin-top: 1rem;
}

.posts-meta {
  font-size: 1.2rem;
  line-height: 0.8;
}

.posts-summary {
  margin-bottom: 1rem;
}

.post-heading {
  font-weight: 600;
  line-height: 1;
  letter-spacing: -.05rem;
  margin-top: 1rem;
}

.post-meta {
  font-size: 1.2rem;
  line-height: 0.8;
  margin-bottom: 2rem;
}

.post-body {
  margin-top: 1rem;
}


@media (min-width: 40em) {
  .posts-heading {
    /* font-size: 50px; */
  }
  .posts-meta {
    /* line-height: 0.5; */
  }
}

@media (min-width: 62em) {
  .posts-heading {
    /* margin-top: 7rem; */
  }
  .posts-meta {
    /* line-height: 0.5; */
  }
}